import React from 'react'
import { graphql } from 'gatsby'
import Layout from '~/components/Layout'
import SEO from '~/components/SEO'
import { PageByUrlPathQuery, IGasbyPageProps } from '../types'
import PageView from '~/components/PageView'


interface IPageViewTemplateProps extends IGasbyPageProps {
  data: PageByUrlPathQuery
}

const PageViewTemplate: React.FC<IPageViewTemplateProps> = ({ data, uri }) => {
  const fm = data.markdownRemark.frontmatter
  const html = data.markdownRemark.html

  const cover = fm.cover
    ? fm.cover.childImageSharp.fluid
    : null

  const images = fm.images ? fm.images.map(img => img.childImageSharp.fluid) : null


  const coverSize =
    cover && fm.cover.childImageSharp.original
      ? { width: fm.cover.childImageSharp.original.width, 
          height: fm.cover.childImageSharp.original.height }
      : null

  const page = {
    title: fm.title,
    html,
    cover,
    images,
    coverSize,
  }
  return (
    <Layout uri={uri}>
      <PageView page={page}></PageView>
      <SEO title={page.title} description={fm.desc}></SEO>
    </Layout>
  )
}
export default PageViewTemplate

export const query = graphql`
  query pageByURLPath($urlPath: String!, $contentWidth: Int!) {
    markdownRemark(fields: { urlPath: { eq: $urlPath } }) {
      html
      frontmatter {
        title
        desc
        cover {
          childImageSharp {
            fluid(maxWidth: $contentWidth, quality: 70) {
              ...GatsbyImageSharpFluid
            }
            original {
              height
              width
            }
          }
        }
        images {
          childImageSharp {
            fluid(maxWidth: $contentWidth, quality: 70) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`