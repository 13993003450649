import React from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'
import Img from 'gatsby-image'
import { IPageData } from '~/types'
import ArticleContainer from './ArticleContainer'
import { contentWidth } from '~/utils/styling'
import { useAppContext } from '~/contexts/appContext'
import Carousel from 'react-images'
import { GatsbyImageSharpFluidFragment } from '~/types'
import bgdark from '~/assets/bg-dark-3@2x.png'

interface IPageViewProps {
  page: IPageData
}

const CarouselWrapper = styled.div`
  width: 100%;
  background-image: url(${bgdark});
  background-size: 160px 160px;
  overflow: hidden;
  border-bottom: 1px solid white;
`

const View: React.FC<{ data: GatsbyImageSharpFluidFragment }> = ({ data }) => (
  <Img
    fluid={data}
    style={{
      maxWidth: `1120px`,
      maxHeight: `640px`,
      margin: `auto`,
    }}
    //@ts-ignore
    objectFit="cover"
    objectPosition="50% 50%"
  ></Img>
)

const PageView: React.FC<IPageViewProps> = ({ page }) => {
  return (
    <>
      <CarouselWrapper>
        <Carousel views={page.images} components={{ Footer: null, View }} />
      </CarouselWrapper>
      <ArticleContainer>
        <header className="fixed-width">
          <h2>{page.title}</h2>
        </header>
        <main className="fixed-width">
          <div dangerouslySetInnerHTML={{ __html: page.html }} />
        </main>
      </ArticleContainer>
    </>
  )
}

export default PageView