import React from 'react'
import styled from 'styled-components'
import { linesRem } from '~/utils/styling'

const StyledDiv = styled.div`
  padding: ${linesRem(1.5)} 5px;
  h2,
  h3,
  h4,
  h5,
  h6,
  p {
    text-shadow: 0 1px 0 white;
    a:active,
    a:hover {
      text-shadow: none;
    }
  }
`

const ArticleContainer: React.FC = ({ children }) => {
  return <StyledDiv>{children}</StyledDiv>
}

export default ArticleContainer